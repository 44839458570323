<!--
 * @Descripttion:
 * @version:
 * @Author: 张天鹏
 * @Date: 2020-04-29 14:57:35
 * @LastEditors: 张天鹏
 * @LastEditTime: 2020-05-27 10:34:39
-->
<template>
  <div class="set_content" >
    <div class="wd_change" v-if="!isPopupShow">
      <div class="change_item">
        <div class="ci_left">
          <span>开户银行</span>
        </div>
        <div class="ci_right">
          <input v-model="bankname"
                 type="text"
                 @click="changeBank"
                 readonly
                 placeholder="请选择开户银行" />
          <md-selector v-model="isSelectorShow"
                       :data="bankList"
                       :default-value="form.bankcode"
                       title="--请选择开户行--"
                       max-height="320px"
                       @choose="onSelectorChoose"></md-selector>
        </div>
      </div>
      <div class="change_item">
        <div class="ci_left">
          <span>开户支行</span>
        </div>
        <div class="ci_right">
          <input v-model="form.bankzh"
                 type="text"
                 placeholder="请输入开户支行" />
        </div>
      </div>
      <div class="change_item">
        <div class="ci_left">
          <span>银行卡号</span>
        </div>
        <div class="ci_right">
          <input v-model="form.bankno"
                 type="bankCard"
                 maxlength="19"
                 placeholder="请输入银行卡号" />
        </div>
      </div>
      <div class="change_pic">
        <div class="ci_left">
          <span>银行卡影像</span>
        </div>
        <simple-cropper :initParam="uploadParam"
                        :successCallback="uploadHandle"
                        :successBank="successSeeBank"
                        ref="cropper"
                        class="box">
          <div class="p_pic"
               @click="upload('cropper')">
            <img v-if="form.bankhttp != ''"
                 :src="ossurl+form.bankhttp"
                 alt=""
                 class="mainpic" />
            <img v-else
                 src="@/assets/image/w_yhk.png"
                 class="mainpic" />
          </div>
        </simple-cropper>
      </div>
    </div>
    <div class="btn" v-if="!isPopupShow">
      <button class="quitBtn delBtn"
              v-if="this.changeType == 'xg'"
              @click="warnDialog.open = true">删除</button>
      <button class="quitBtn truebtn"
              @click="submit"
              :disabled="jinyong">确定</button>
    </div>
<md-dialog title="删除" :closable="false" v-model="warnDialog.open" :btns="warnDialog.btns" >
      您确定要删除该银行卡相关信息吗？
    </md-dialog>    <md-popup v-model="isPopupShow" :mask-closable="closable">
          <div class="icdoling">
              <md-icon name="spinner" color="blue" size="lg" style="-webkit-filter:invert(1)"></md-icon>
          </div>
        </md-popup>
  </div>
</template>
<script>
import { selectOneYHK, saveEmpYHK, updateEmpYHK, deleteEmpYHK } from '@/api/basic/index'
import simpleCropper from './component/simpleCropper'
import { selectBaseBankcode } from '@/api/agent/agent/index'
import { getStorage } from '@/lib/util'
import { Toast, Dialog } from 'mand-mobile'
export default {
  components: {
    simpleCropper
  },
  data () {
    return {
      closable: false,
      isPopupShow: true,
      ossurl: '',
      form: {
        bankcode: '',
        bankzh: '',
        bankno: '',
        bankhttp: ''
      },
      bankList: [],
      isSelectorShow: false,
      bankname: '',
      uploadParam: {
        file: 'bank',
        type: 'bank',
        fileType: 'recruit', // 其他上传参数
        uploadURL: '/empno/getUpload', // 上传地址
        scale: 4, // 相对手机屏幕放大的倍数: 4倍
        ratio: 5 / 3
      }, // 上传头像初始化数据
      changeType: '',
      bankid: '',
      jinyong: false,
      warnDialog: {
        open: false,
        btns: [
          {
            text: '取消'
          },
          {
            text: '删除',
            warning: true,
            handler: this.delbank
          }
        ]
      }
    }
  },
  created () {
    this.ossurl = getStorage('ossurl', '')
    this.selectBankcode()
    this.changeType = this.$route.query.changeType
    if (this.changeType == 'tj') {
      this.isPopupShow = false
    }
    this.bankid = this.$route.query.id ? this.$route.query.id : ''
  },
  methods: {
    getData () {
      selectOneYHK({ id: this.bankid }).then(res => {
        this.isPopupShow = false
        let Data = res.data.data
        this.form.bankcode = Data.bankcode
        this.form.bankzh = Data.bankzh
        this.form.bankno = Data.bankno
        this.form.bankhttp = Data.bankhttp
        this.bankList.forEach((item, index) => {
          if (item.bankid == this.form.bankcode) {
            this.bankname = item.bankname
            // this.$forceUpdate()
            // console.log(this.bankname)
          }
        })
      })
    },
    // 银行卡下拉框
    selectBankcode () {
      selectBaseBankcode().then(res => {
        this.bankList = res.data.data
        this.bankList.forEach((item, index) => {
          this.$set(this.bankList[index], 'value', item.bankid)
          this.$set(this.bankList[index], 'text', item.bankname)
        })
      }).finally(() => {
        if (this.changeType == 'xg') {
          this.getData()
        }
      })
    },
    changeBank () {
      this.isSelectorShow = true
    },
    onSelectorChoose ({ text, value }) {
      this.form.bankcode = value
      this.bankname = text
    },
    // 上传图片
    upload (ele) {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        console.log(this.$refs[ele].upload())
      }
      this.$refs[ele].upload()
    },
    // 识别银行卡成功回调
    successSeeBank (data) {
      if (data != '' && data != null) {
        Toast.succeed('银行卡信息识别成功')
        this.form.bankno = data.bank_card_number.replace(/\s/g, '')
        this.bankList.forEach((item, index) => {
          if (data.bank_name == item.bankname) {
            this.bankname = item.bankname
            this.form.bankcode = item.bankid
          }
        })
      } else {
        Toast.info('未能识别出银行卡信息')
      }
    },
    // 上传图片成功回调
    uploadHandle (data) {
      this.form.bankhttp = data.path
      Toast.succeed('上传成功')
    },
    submit () {
      if (!this.form.bankhttp) {
        Toast.failed('请先上传银行卡影像')
        return
      }
      if (!this.form.bankcode) {
        Toast.failed('请输入开户银行')
        return
      }
      // if (!this.form.bankzh) {
      //   Toast.failed('请输入开户支行')
      //   return
      // }
      if (!this.form.bankno) {
        Toast.failed('请输入银行卡号')
        return
      }
      let bankReg = /^\d{15,}$/
      if (!bankReg.test(this.form.bankno)) {
        Toast.failed('银行卡号不符合规范')
        return
      }
      this.jinyong = true
      if (this.changeType == 'tj') {
        saveEmpYHK(this.form).then(res => {
          Toast.succeed('添加成功')
          this.$router.push('/bankList')
        }).finally(() => {
          this.jinyong = false
        })
      } else {
        this.$set(this.form, 'id', this.bankid)
        updateEmpYHK(this.form).then(res => {
          Toast.succeed('修改成功')
          this.$router.push('/bankList')
        }).finally(() => {
          this.jinyong = false
        })
      }
    },
    // 删除银行卡提示
    delbank () {
      this.warnDialog.open = false
      deleteEmpYHK({ id: this.bankid }).then((res) => {
        Toast.succeed('已删除')
        this.$router.push('/bankList')
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
/deep/ .icdoling .md-icon {
          width: 1.42rem;
          height: 1.42rem;
          line-height: 1.42rem;
      }
.set_content {
  background-color: #F6F6F6;

  .wd_change {
    // border-top 50px solid #F6F6F6
    background-color: #FFFEFF;

    // padding-bottom 150px
    .change_item {
      overflow: hidden;
      border-bottom: 1px solid #F6F6F6;
      padding: 50px 60px;

      .ci_left {
        display: inline-block;
        width: 30%;

        span {
          font-size: 0.39rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(56, 56, 56, 1);
        }
      }

      .ci_right {
        float: right;
        width: 70%;
        text-align: right;

        span {
          font-size: 0.39rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(56, 56, 56, 1);
        }

        input {
          overflow: hidden;
          border: none;
          text-align: right;
          outline: none;
          font-size: 0.39rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(56, 56, 56, 1);
          width: 100%;
        }
      }
    }

    .change_pic {
      padding: 50px 60px;

      .ci_left {
        padding-bottom: 50px;

        span {
          font-size: 0.39rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(56, 56, 56, 1);
        }
      }
    }
  }

  .btn {
    text-align: center;
    padding-top: 200px;
    display: flex;

    .quitBtn {
      padding: 30px 30px;
      margin: 80px 0.5rem;
      width: 90%;
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      border-radius: 100px;
    }

    .truebtn {
      flex: 1;
      background-color: color-primary;
    }

    .delBtn {
      flex: 1;
      background-color: #CCCCCC;
    }
  }
}

.solidTop {
  border-top: 50px solid #F6F6F6;
}

.mainpic {
  width: 100%;
  height: auto;
}
</style>
